var HEADER_SMALL_OFFSET = 10;
var HEADER_HIDE_OFFSET = 200;

var HEADER_HIDE_MENU = false;

$(function() {
    // Restore last position of the scrollbar
    if (typeof(Storage) !== "undefined") {
        if (localStorage.getItem("scroll") !== null) {
            $(document).scrollTop(localStorage.getItem("scroll"));
            localStorage.removeItem("scroll");
        }

        $('form').on("submit", function() {
            localStorage.setItem("scroll", $(document).scrollTop());
        });
    }


    // Show popovers in the about-us image of members
    $('section.about-us area').each(function() {
        // Create popover
        $(this).popover({
            trigger: 'manual',
            placement: 'bottom',
            container: '#about-us-popover-container'
        });
        // Place the popover when hover
        $(this).mouseover(function(e) {
            $(this).popover("show");

            var popover = $(".popover");
            popover.css({
                top: e.clientY + 100,
                left: e.clientX
            });

        });
        // Hide the popover when gone
        $(this).mouseout(function(e) {
            $(this).popover("hide");
        });
    });

    // Check scroll
    var lastScrollTop = 0;

    $(window).scroll(function() {
        var st = $(this).scrollTop();

        if (st > HEADER_SMALL_OFFSET) {
            // Header small
            headerSmall();

            if (st > lastScrollTop) {
                // Downscroll
                if (HEADER_HIDE_MENU && st > HEADER_HIDE_OFFSET) {
                    // Do not hide the menu
                    // headerMenuHide();
                }
            } else {
                // Upscroll
                headerMenuShow();
            }
        } else {
            // Header big
            headerBig();
            headerMenuShow();
        }

        lastScrollTop = st;
    });


    setTimeout(function() {
        // Don't use transitions the first time
        $("#header-menu").addClass("transitions");

        // Allow the menu to be hidden
        HEADER_HIDE_MENU = true;
    }, 200);


    // Forms
    $('#subscribe-form').submit(function(event) {
        var $form = $(this);
        var $email = $form.find('.subscribe-email');
        var $submit = $form.find("button[type='submit']");

        if ($submit.hasClass('disabled') || $email.val() == '') {
            return false;
        }

        var formData = {
            'email': $email.val(),
            '_token': $form.find("input[name='_token']").val()
        };

        $submit.html('<i class="fa fa-spin fa-cog"></i>').addClass('disabled');
        $form.find('.alert').remove();

        $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: formData,
                dataType: 'json',
                encode: true
            })
            .done(function(data) {
                $submit.html('Subscribe').removeClass('disabled');
                $form.prepend('<div class="alert alert-' + (data.success ? 'success' : 'danger') + '">' + data.message + '</div>');
            });

        event.preventDefault();
    });

    // Datetime picker
    $('.datetimepicker').datetimepicker({
        format: 'YYYY-MM-DD'
    });

    $('#application-form').on('submit', function() {
        dataLayer.push({
            'event': 'applicationFormSubmitted'
        });
    });

    $('#carmelite_affiliation').on('change', function() {
        hideCommentCarmelite();
        if(this.value == 'sr') {
            showCommentCarmelite();
        }
    });
});

function headerBig() {
    $("#header-menu").removeClass("menu-small");
}

function headerSmall() {
    $("#header-menu").addClass("menu-small");
}

function headerMenuShow() {
    $("#header-menu").removeClass("menu-hidden");
}

function headerMenuHide() {
    $("#header-menu .dropdown").removeClass("open");
    $("#header-menu").addClass("menu-hidden");
}

function showCommentCarmelite() {
    $(".carmelite-other").removeClass('hidden');
}

function hideCommentCarmelite() {
    $(".carmelite-other").addClass('hidden');
}
